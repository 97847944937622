const CONST = require('plugin_frontend_core/constants');
const cookieUtils = require('plugin_frontend_core/utils/cookie-util');
const { exists } = require('plugin_frontend_core/utils/utils_cw');

const selectors = {
    cookieConsent: '.js-cookie-consent',
    cookieConsentOptions: {
        container: '.js-cookie-consent-options',
        item: '.js-cookie-consent-option'
    },
    cookieFeedback: '.js-cookie-feedback',
    cookieNotification: '.js-cookie-notification',
    cookieButton: '.js-cookie-button',
    cookieSettingsButton: '.js-cookie-settings-button',
    cookieSettingsToggle: '.js-collapse-toggler-btn',
    cookieModal: '.js-cookie-modal',
    cookieEditButton: '.js-change-cookies',
};

const classes = {
    notificationHidden: 'cookie-notification--hidden',
    overlay: 'has-overlay',
    showModal: 'show-modal'
};

const attributes = {
    cookieButtonType: 'data-cookie-button-type',
    cookie: {
        name: 'data-cookie-name',
        value: 'data-cookie-value',
        lifetime: 'data-cookie-lifetime'
    }
};

const cookieTypes = {
    useAllCookies: 'useAllCookies',
    useSelectCookies: 'useSelectCookies',
    rejectCookies: 'rejectCookies'
};

const cookieNames = {
    functional: 'useFunctionalCookies',
    analytical: 'useAnalyticalCookies',
    marketing: 'useMarketingCookies',
    social: 'useSocialCookies'
};


let cookies = [];

/**
 * @private hideCookieNotification
 * @description Hides the cookie notification
 */
function hideCookieNotification() {
    const cookieNotification = document.querySelector(selectors.cookieNotification);
    const modal = document.querySelector(selectors.cookieModal);

    if (!exists(cookieNotification)) {
        console.error(`hideCookieNotification: required element(s) missing: ${selectors.cookieNotification}`); // eslint-disable-line no-console
        return;
    }

    // Remove classes associated with showing the cookie modal
    if (modal) {
        modal.classList.remove(classes.showModal);
        document.body.classList.remove(classes.overlay);
    }

    cookieNotification.classList.add(classes.notificationHidden);
}

/**
 * @private setTrackingConsent
 * @description
 *      Sets tracking consent for the below cookies to true or false based on 'useAnalyticalCookies' value
 *      - __cq_dnt
 *      - dw_dnt
 * @param {boolean} boolean - true or false
 */
function setTrackingConsent(boolean) {
    const endpoint = 'sfUrls' in window && window.sfUrls.cookies.consentApi;
    if (endpoint) {
        $.ajax({
            url: `${endpoint}?consent=${boolean}`,
            method: 'GET',
            error: function () {
                console.error('setTrackingConsent: Could not set tracking consent'); // eslint-disable-line no-console
            }
        });
    }
}

/**
 * @private onCookieSubmit
 * @description Response handler for handling the cookie submission
 */
function onCookieSubmit() {
    const cookieFeedback = document.querySelector(selectors.cookieFeedback);

    if (!exists(cookieFeedback)) {
        console.error(`onCookieSubmit: required element(s) missing: ${selectors.cookieFeedback}`); // eslint-disable-line no-console
        return;
    }

    cookieFeedback.classList.remove(CONST.classes.dNone);
}

/**
 * @private getCookieAttributes
 * @description Gets all required cookie attributes from the passed cookie parameter
 * @param {Node} cookie - the cookie to get the attributes from
 * @returns {Object} - the cookie object with its attributes
 */
function getCookieAttributes(cookie) {
    const cookieObject = {};

    const name = cookie.getAttribute(attributes.cookie.name);
    const value = cookie.checked;
    const lifetime = cookie.getAttribute(attributes.cookie.lifetime);
    if (!name || !lifetime) {
        console.error(`getCookieAttributes: required attribute(s) missing: ${attributes.cookie.name} || ${attributes.cookie.value} || ${attributes.cookie.lifetime}`); // eslint-disable-line no-console
        return null;
    }

    cookieObject.name = name;
    cookieObject.value = value;
    cookieObject.lifetime = lifetime;

    return cookieObject;
}

/**
 * @private getSelectedCookies
 * @description
 *      Gets all the cookies and, based on their values, pushes them to an array
 *      This array will then be used to set cookies based on their value
 * @param {EventTarget} target - the target to get the selected cookies from
 * @param {string} type - the type of the target
 */
function getSelectedCookies(target, type) {
    // Reset the cookies array
    cookies = [];

    const cookieElement = target.closest(selectors.cookieNotification) || target.closest(selectors.cookieConsent);
    if (!cookieElement) {
        console.error(`getSelectedCookies: required element(s) missing: ${selectors.cookieNotification} || ${selectors.cookieConsent}`); // eslint-disable-line no-console
        return;
    }

    const cookieOptionsContainer = cookieElement.querySelector(selectors.cookieConsentOptions.container);
    if (!exists(cookieOptionsContainer)) {
        console.error(`getSelectedCookies: required element(s) missing: ${selectors.cookieConsentOptions.container}`); // eslint-disable-line no-console
        return;
    }

    const cookieOptions = cookieOptionsContainer.querySelectorAll(selectors.cookieConsentOptions.item);
    if (!exists(cookieOptions)) {
        console.error(`getSelectedCookies: required element(s) missing: ${selectors.cookieConsentOptions.item}`); // eslint-disable-line no-console
        return;
    }
    var requiredCookies = [cookieNames.functional, cookieNames.analytical];

    for (const cookieOption of cookieOptions) {
        const cookieOptionName = cookieOption.dataset.cookieName;
        if (type === cookieTypes.useAllCookies) {
            // If the 'use all cookies' button was used, check all cookie checkboxes
            cookieOption.checked = true;
        } else if (type === cookieTypes.rejectCookies) {
            // If the 'reject cookies' button was used, check only required cookies
            cookieOption.checked = requiredCookies.includes(cookieOptionName);
        }

        const cookie = getCookieAttributes(cookieOption);
        if (!cookie) {
            console.error('getSelectedcookies: required element(s) missing; refer to getCookieAttributes function'); // eslint-disable-line no-console
            return;
        }

        if (cookieOption.checked) {
            // Set all cookies based on the selected cookieOptions individually
            cookieUtils.setCookie(cookie.name, cookie.value, cookie.lifetime);
        } else {
            // Remove non checked cookie from the document.cookie
            cookieUtils.deleteCookie(cookie.name);
        }

        cookies.push(cookie);
    }

    cookieUtils.setCookie('acceptedCookiePolicy', true, 365);

    // Find all cookies inside the cookies array in order to pass their values to the dataLayer
    const functionalCookie = cookies.find(cookie => cookie.name === cookieNames.functional);
    const analyticalCookie = cookies.find(cookie => cookie.name === cookieNames.analytical);
    const marketingCookie = cookies.find(cookie => cookie.name === cookieNames.marketing);
    const socialCookie = cookies.find(cookie => cookie.name === cookieNames.social);

    // Set SFCC tracking consent for '__cq_dnt' and 'dw_dnt' analytical/statistical cookies based on if this field is checked
    if (analyticalCookie && 'value' in analyticalCookie) {
        setTrackingConsent(analyticalCookie.value);
    }

    // Hide the cookie notification if the target is inside the cookie notification
    if (cookieElement.classList.contains(selectors.cookieNotification.slice(1))) {
        hideCookieNotification();
    }

    // Handle cookie submission if the target is inside the cookie consent
    if (cookieElement.classList.contains(selectors.cookieConsent.slice(1))) {
        onCookieSubmit();
    }

    // Add cookie values to the dataLayer
    if (window.dataLayer) {
        window.dataLayer.push({
            cookies: {
                functional: !!functionalCookie.value,
                analytical: !!analyticalCookie.value,
                marketing: !!marketingCookie.value,
                social: !!socialCookie.value
            },
            event: 'cookie_preferences'
        });
    }
}

/**
 * @private onCookiePreferenceClick
 * @description Handles click event on cookie preference buttons
 * @param {Event} event - the event
 */
function onCookiePreferenceClick(event) {
    const { currentTarget } = event;

    const targetType = currentTarget.getAttribute(attributes.cookieButtonType);
    if (!targetType) {
        console.warn(`setCookie: required attribute(s) missing: ${attributes.cookieButtonType}`); // eslint-disable-line no-console
        return;
    }

    getSelectedCookies(currentTarget, targetType);
}

/**
 * @private showModal
 * @description handles setting classes for the modal
 */
function showModal() {
    const modal = document.querySelector(selectors.cookieModal);
    const cookieNotification = document.querySelector(selectors.cookieNotification);

    if (cookieNotification) {
        cookieNotification.classList.remove(classes.notificationHidden);
    }

    if (modal) {
        modal.classList.add(classes.showModal);
        document.body.classList.add(classes.overlay);
    }
}

/**
 * @private swapButtons
 * @description event handler to swap the settings button with the save button
 * @param {Event} event - the event
 */
function swapButtons(event) {
    const btnSettings = event.currentTarget;
    const btnRejectCookies = document.querySelector(`[data-cookie-button-type="${cookieTypes.rejectCookies}"]`);
    const btnAllCookies = document.querySelector(`[data-cookie-button-type="${cookieTypes.useAllCookies}"]`);
    const btnSelectCookies = document.querySelector(`[data-cookie-button-type="${cookieTypes.useSelectCookies}"]`);
    const displayNone = CONST.classes.dNone;

    if (btnSettings && !(btnSettings.classList.contains(displayNone))) {
        btnSettings.classList.add(displayNone);
        btnRejectCookies.classList.remove(displayNone);
        btnAllCookies.classList.add(displayNone);
        btnSelectCookies.classList.remove(displayNone);
    }
}

/**
 * @private handleDropDownActiveState
 * @description manipulates the dropdown toggle button for each cookie on click
 */
function handleDropDownActiveState() {
    const btnDropdowns = document.querySelectorAll(selectors.cookieSettingsToggle);

    if (btnDropdowns && btnDropdowns.length !== -1) {
        Array.from(btnDropdowns).forEach(btn => {
            btn.addEventListener(CONST.events.click, () => {
                btn.classList.toggle(CONST.classes.active);
            });
        });
    }
}

/**
 * @private getCookieModal
 * @description gets the cookie modal
 */
function getCookieModal(){
    const endpoint = 'sfUrls' in window && window.sfUrls.cookies.getSettingsModal;
    if (endpoint) {
        $.ajax({
            url: `${endpoint}?force=true`,
            method: 'GET',
            success: function( modalHtml ) {
                $('body').append(modalHtml);
                init();
            },
        });
    }
}

/**
 * @private openCookieSettings
 * @description gets and opens the modal with the cookiesettings
 */
function openCookieSettings(e) {
    e.preventDefault();
    const cookieNotification = document.querySelector(selectors.cookieNotification);
    if (!exists(cookieNotification)) {
        getCookieModal();
    }else{
        showModal();
    }
}


/**
 * @public init
 * @description Initializes the module
 */
function init() {
    const cookieButtons = document.querySelectorAll(selectors.cookieButton);
    const btnSettings = document.querySelector(selectors.cookieSettingsButton);

    const openCookieSettingsButton = document.querySelector(selectors.cookieEditButton);
    if (exists(openCookieSettingsButton)) {
        openCookieSettingsButton.addEventListener(CONST.events.click, openCookieSettings);
    }

    if (!exists(cookieButtons)) {
        return;
    }

    for (const cookieButton of cookieButtons) {
        cookieButton.addEventListener(CONST.events.click, onCookiePreferenceClick);
    }

    // Swap the buttons when clicking on instellen
    btnSettings.addEventListener(CONST.events.click, swapButtons);

    showModal();
    handleDropDownActiveState();
}

module.exports = {
    init: init
};
