const orange = require('app_clockwork_orange/main');
const processInclude = require('app_storefront_base/util');

// Extends the Orange JS and overwrites methods with new definitions
module.exports = $.extend(orange, {
    // method1: () => {},
    // method2: method2
});

window.onjQueryLoad(function () {
    // Header components
    processInclude(require('./components/header'));
    processInclude(require('./components/customer-service-popover'));
    processInclude(require('plugin_account/components/loginOverlay'));
    processInclude(require('./components/header-announcement-slot'));
    processInclude(require('app_schuurman_sooco/components/countrySelector'));
}, true);
